import React, { Component } from 'react'
import './Aluno.scss'
import './../../main/ultil.scss'

import { Link } from 'react-router-dom'
import moment from 'moment'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/TableV2'
import axios from 'axios'
import { Grid, Button, TextField } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalErro from './../../components/modals/Erro'

const initalState = {
  aluno: {

  },
  alunos: {
    list: []
  },
  alunosFiltrado: {
    list: []
  },
  filtro: {
    aluno_matricula: "",
    pesf_nome: "",
    pesf_cpf: "",
    stcontmat_situacao: "APROVADO",
    filtrar_por: "Subscrição - aluno_matricula",
    value: '',
    limit: 10,
    offset: 0,
  },
  rows: 0,
  cabecalhoTabela: [
    { id: 'aluno_matricula', numeric: false, disablePadding: false, label: 'Subscrição', orderBy: 'aluno_matricula_ordem' },
    { id: 'pesf_nome', numeric: false, disablePadding: false, label: 'Nome' },
    { id: 'pesf_cpf', numeric: false, disablePadding: false, label: 'CPF' },
    { id: 'telefone', numeric: false, disablePadding: false, label: 'Telefone' },
    { id: 'stcontmat_situacao', numeric: false, disablePadding: false, label: 'Situação' },
  ],
  acoesTabela: [],
  loading: true,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  filtroAluno: {
    label: 'Subscrição',
    name:'aluno_matricula'
  }
}

function formatPhoneNumber(phone) {
  const regex = /^(\d{2})(\d{2})(\d{4,5})(\d{4})$/;
  
  if (!regex.test(phone)) {
      return "";
  }

  return phone.replace(regex, "+$1 ($2) $3-$4");
}

export default class Aluno extends Component {
  state = { ...initalState }
  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    localStorage.removeItem('filtro_financeiro_aluno');
    localStorage.removeItem('aula_selecionada_agenda');

    let filtro = {
      aluno_matricula: "",
      pesf_nome: "",
      pesf_cpf: "",
      stcontmat_situacao: "APROVADO",
      filtrar_por: "Subscrição - aluno_matricula",
      value: '',
      limit: 10,
      offset: 0,
    }

    this.setState({
      filtro
    })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'alunos')[0]

    if (!permissoes) {
      permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'nova-matricula')[0]
    }

    let acoesTabela = []

    if (permissoes.perm_visualizar) {
      acoesTabela.push('viewAluno')
    }

    if (permissoes.perm_alterar && permissoes.tela.modulo.mod_slug !== 'nova-matricula') {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    await this.filtrarAluno(filtro, true)
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFiltro(event) {
    const filtro = this.state.filtro

    if (event.target.name === 'filtrar_por') {    
      this.setState({
        filtroAluno: {
          label: event.target.value.split(' - ')[0],
          name: event.target.value.split(' - ')[1]
        }
      })

      filtro[event.target.name] = event.target.value

      this.setState({
        filtro
      })
    }else{
      filtro[event.target.name] = event.target.value
      
      this.setState({
        filtro
      })
    }
  }

  async filtrarAluno(filtro, resetTable) {
    this.setState({
      loading: true,
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      filtro = {
        aluno_matricula: "",
        pesf_nome: "",
        pesf_cpf: "",
        stcontmat_situacao: filtro.stcontmat_situacao,
        filtrar_por: filtro.filtrar_por,
        value: filtro.value,
        limit: filtro.limit,
        offset: filtro.offset * filtro.limit,
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      if(resetTable){
        filtro.limit = 10
        filtro.offset = 0
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/list/filtro`, filtro, this.getToken())

      this.setState({
        alunos: {
          list: data.list.map(aluno => {
            let stcontmat_situacao = ''

            if (aluno.stcontmat_situacao === 'APROVADO') {
              stcontmat_situacao = 'Aprovado'
            } else if (aluno.stcontmat_situacao === 'REPROVADO') {
              stcontmat_situacao = 'Reprovado'
            } else if (aluno.stcontmat_situacao === 'INATIVO') {
              stcontmat_situacao = 'Inativo'
            } else if (aluno.stcontmat_situacao === 'AGUARDANDO_APROVACAO') {
              stcontmat_situacao = 'Aguardando Aprovação'
            } else if (aluno.stcontmat_situacao === 'TRANSFERIDO') {
              stcontmat_situacao = 'Transferido'
            } else if (aluno.stcontmat_situacao === 'CANCELADO') {
              stcontmat_situacao = 'Cancelado'
            } else if (aluno.stcontmat_situacao === 'ENCERRADO') {
              stcontmat_situacao = 'Encerrado'
            } else if (aluno.stcontmat_situacao === 'AGUARDANDO_TRANFERENCIA') {
              stcontmat_situacao = 'Aguardando Transferência'
            } else if (aluno.stcontmat_situacao === 'TRANSFERENCIA_RECUSADA') {
              stcontmat_situacao = 'Transferência Recusada'
            } else if (aluno.stcontmat_situacao === 'AGUARDANDO_ASSINATURA') {
              stcontmat_situacao = 'Aguardando Assinatura'
            }
    
            return {
              _id: aluno.aluno_id,
              aluno_matricula_ordem: parseInt((aluno.aluno_matricula ? aluno.aluno_matricula : '.').replace('.','')),
              aluno_matricula: aluno.aluno_matricula ? aluno.aluno_matricula : '',
              pesf_nome: `${aluno.pesf_nome}${aluno.pesf_sobrenome ? ` ${aluno.pesf_sobrenome}` : ''}`,
              pesf_cpf: aluno.pesf_cpf,
              telefone: formatPhoneNumber(aluno.tel_numero),
              stcontmat_situacao
            }
          })
        },
        loading: false,
        rows: data.rows,
        filtro
      })

    } catch (error) {
      console.log(error)
      this.setState({
        modalErro: true,
        msgErro: error,
      })
    }
  }

  async handleChangePage(event, newPage){
    const {filtro} = this.state
    filtro.offset = newPage

    this.setState({
      filtro
    })

    await this.filtrarAluno(filtro, false)
  }

  async handleChangeRowsPerPage(event){
    const {filtro} = this.state

    filtro.limit = parseInt(event.target.value)

    this.setState({
      filtro
    })

    await this.filtrarAluno(filtro, false)
  }

  render() {
    const { filtro, alunos, permissoes, rows } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Alunos...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Alunos</h1>
                </Grid>
                {permissoes.perm_inserir &&
                  <Grid item md={4} xs={12}>
                    <Link to="/aluno/cadastro">
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Aluno
								    </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Filtrar por"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="filtrar_por"
                    value={filtro.filtrar_por}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="Subscrição - aluno_matricula"> Subscrição </option>
                    <option value="Nome - pesf_nome"> Nome </option>
                    <option value="CPF - pesf_cpf"> CPF </option>
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label={this.state.filtroAluno.label} variant="outlined" size="small" name='value' value={filtro.value} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Situação"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="stcontmat_situacao"
                    value={filtro.stcontmat_situacao}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""> Pesquisar </option>
                    <option value="APROVADO"> Aprovado </option>
                    <option value="AGUARDANDO_APROVACAO"> Aguardando Aprovação </option>
                    <option value="AGUARDANDO_ASSINATURA"> Aguardando Assinatura </option>
                    <option value="AGUARDANDO_TRANFERENCIA"> Aguardando Transferência </option>
                    <option value="CANCELADO"> Cancelado </option>
                    <option value="ENCERRADO"> Encerrado </option>
                    <option value="REPROVADO"> Reprovado </option>
                    <option value="INATIVO"> Inativo </option>
                    <option value="TRANSFERIDO"> Transferido </option>
                    <option value="TRANSFERENCIA_RECUSADA"> Transferência Recusada </option>
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrarAluno(filtro, true)}>
                    Filtrar
                  </Button>
                </Grid>
              </Grid>
              {/* <Grid container direction="row" spacing={1} className="mg_top_20">
                
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Nome" variant="outlined" size="small" name="pesf_nome" value={filtro.pesf_nome} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                
              </Grid> */}
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <Table 
                    urlUpdate="/aluno/cadastro/" 
                    urlView="/aluno/view/" 
                    headCell={this.state.cabecalhoTabela} 
                    rows={alunos.list} 
                    acoes={this.state.acoesTabela}
                    count={rows}
                    filtro={filtro}
                    handleChangePage={(event, newPage) => this.handleChangePage(event, newPage)}
                    handleChangeRowsPerPage={e => this.handleChangeRowsPerPage(e)}
                  />
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}